import { map, Observable, switchMap } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CertificationType } from 'src/app/models/company/certificate';
import { NgHttpCachingService } from 'ng-http-caching';

export interface NotificationEmails {
  enabled: boolean;
  emails: string[];
}

export interface Company {
  created?: Date;
  updated?: Date;
  deleted?: Date;
  versionHistory?: null;
  version?: number;
  id?: string;
  customerId?: string;
  name: string;
  phone: string;
  mail?: string;
  gln: string;
  url: string;
  fn: string;
  uid?: string;
  vatCode?: string;
  type?: string;
  types: CompanyType[];
  verified?: boolean;
  registrationCompleted?: boolean;
  registrationStarted?: boolean;
  wasticsAgbAccepted?: boolean;
  matchmakingBeta?: boolean;
  inactive?: boolean;
  payingCustomer?: boolean;
  createdByWastics?: boolean;
  banned?: boolean;
  status?: CompanyStatus;
  certifications?: CertificationType[];
  legalEntityType?: string;
  contactEmails?: NotificationEmails;
  invoiceEmails?: NotificationEmails;
  inquiryEmails?: NotificationEmails;
  offerEmails?: NotificationEmails;
  contractEmails?: NotificationEmails;
  newsletterEmails?: NotificationEmails;
  originType?: String;
  provisionPercentage?: string;
}

export type CompanyStatus = 'gesperrt' | 'neu' | 'verifiziert' | 'unbekannt';

export const companyTypes = [
  'TRANSPORT',
  'WASTE_CONSULTANT',
  'WASTE_DISPOSAL',
  'SUPPLIER',
  'WASTE_PRODUCER',
  'WASTE_COLLECTOR',
  'CONVERTER',
] as const;

export type CompanyType = (typeof companyTypes)[number];

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  private header = {
    'Content-Type': 'application/json',
    'X-NG-HTTP-CACHING-ALLOW-CACHE': 'true',
  };

  private http = inject(HttpClient);
  private ngHttpCachingService = inject(NgHttpCachingService);

  private addStatus(company: Company): Company {
    if (!company) return company;
    if (company.status) return company;

    let status: CompanyStatus = 'unbekannt';
    if (!company.verified) status = 'neu';
    if (company.verified) status = 'verifiziert';
    if (company.banned) status = 'gesperrt';

    return { ...company, status };
  }

  public getCompanies(): Observable<Company[]> {
    return this.http
      .get<Company[]>(environment.matchMakingApiUrl + '/v1/companies', {
        headers: this.header,
      })
      .pipe(
        map((companies) => companies.map((company) => this.addStatus(company)))
      );
  }

  public getCompany(companyId: string): Observable<Company> {
    return this.http
      .get<Company>(
        environment.matchMakingApiUrl + `/v1/companies/${companyId}`,
        { headers: this.header }
      )
      .pipe(map((result) => this.addStatus(result)));
  }

  public getCompanyByGln(gln: string): Observable<Company> {
    return this.http
      .get<Company>(environment.matchMakingApiUrl + `/v1/companies/gln/${gln}`)
      .pipe(map((result) => this.addStatus(result)));
  }

  public postCompany(company: Company): Observable<Company> {
    this.ngHttpCachingService.clearCacheByRegex(/companies/);

    return this.http
      .post<Company>(
        environment.matchMakingApiUrl + '/v1/companies/',
        company,
        { headers: this.header }
      )
      .pipe(map((result) => this.addStatus(result)));
  }

  public putCompany(company: Company): Observable<Company> {
    this.ngHttpCachingService.clearCacheByRegex(/companies/);

    return this.http
      .put<Company>(environment.matchMakingApiUrl + '/v1/companies/', company, {
        headers: this.header,
      })
      .pipe(map((result) => this.addStatus(result)));
  }

  public verifyCompany(company: Company) {
    return this.http
      .patch<Company>(
        environment.matchMakingApiUrl + '/v1/admin/companies/',
        company
      )
      .pipe(map((result) => this.addStatus(result)));
  }

  public banCompany(companyId: string, isBanned: boolean) {
    return this.getCompany(companyId).pipe(
      switchMap((company) => this.putCompany({ ...company, banned: isBanned }))
    );
  }

  public acceptAgb() {
    this.ngHttpCachingService.clearCacheByRegex(/companies/);

    return this.http.put<Company>(
      environment.matchMakingApiUrl + '/v1/companies/wastics-agb/accept',
      {
        headers: this.header,
      }
    );
  }
}
